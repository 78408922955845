module.exports = {
  defaultLangKey: "en",

  // langs: ['en','ja','zh'],
  langs: [
    { label: "en", slug: "English" },
    { label: "ja", slug: "日本語" },
    { label: "zh", slug: "简体中文" },
    
  ],
};